import React from 'react';
import ptBR from 'antd/lib/locale/pt_BR';
import { ConfigProvider } from 'antd';
import { RouterProvider } from 'react-router-dom';
import { router } from './router/routes';

function App(): JSX.Element {
  return (
    <div className="App">
      <ConfigProvider locale={ptBR}>
        <RouterProvider router={router} />
      </ConfigProvider>
    </div>
  );
}

export default App;
