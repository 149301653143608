import User from '../common/interfaces/User';

import {
  json,
  LoaderFunction,
  LoaderFunctionArgs,
  redirect,
} from 'react-router-dom';
import { withAuth } from './with-auth';

export const withAdmin: (
  loader?: LoaderFunction
) => LoaderFunction | undefined = (loader = () => json(null)) => {
  return withAuth((args: LoaderFunctionArgs) => {
    const data = localStorage.getItem(
      `${process.env.REACT_APP_USER_PROFILE_KEY}`
    );

    if (!data) throw redirect('/dashboard');

    const user: User = JSON.parse(data);

    if (user.role !== 'Administrador') throw redirect('/dashboard');

    return loader(args);
  });
};
