import { json, LoaderFunctionArgs } from 'react-router-dom';
import {
  GET_OS_LIST,
  GetOrderServicesPaginator,
} from '../../../common/GraphQL/GetOSList';
import client from '../../../common/services/apollo';

export type LoaderData = Awaited<ReturnType<typeof loader>>;
export const loader = async ({ params, request }: LoaderFunctionArgs) => {
  const status = params.status;
  const searchParams = new URL(request.url).searchParams;
  const { pageSize, page, ...values } = Object.fromEntries(
    searchParams.entries()
  );

  const pageSizeAsNumber = Number(pageSize) || 10;
  const pageAsNumber = Number(page) || 1;

  const filtersState = {
    ...values,
    metreage: Number(values.metreage) || undefined,
    have_furniture: parseBoolean(values.have_furniture),
    isContestationDone: parseBoolean(values.isContestationDone),
  };

  const { data } = await client.query<GetOrderServicesPaginator>({
    query: GET_OS_LIST,
    fetchPolicy: 'no-cache',
    context: {
      uri: process.env.REACT_APP_VISTORIA_FETCH_SYNC,
    },
    variables: {
      options: {
        offset: pageSizeAsNumber * (pageAsNumber === 1 ? 0 : pageAsNumber - 1),
        limit: Number(pageSize) || 10,
        page: Number(page) || 1,
        orderBy: {
          column: 'orderService.id',
          order: 'DESC',
        },
      },
      filters: { status, ...filtersState },
    },
  });
  return data;
};

function parseBoolean(bool: string) {
  if (bool === 'true') {
    return true;
  } else if (bool == 'false') {
    return false;
  } else {
    return undefined;
  }
}
